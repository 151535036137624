<template>
    <div class="min-h-screen bg-white flex">
    <div class="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div class="mx-auto w-full max-w-sm lg:w-96">
            <div>
                 <div>
            <slot name="logo" />
        </div>
                <h2 class="mt-6 text-3xl font-extrabold text-gray-900 text-center">
                    Sign in to your account
                </h2>
            </div>

            <div class="mt-8">
                <div class="mt-6">
                   <slot />
                </div>
            </div>
        </div>
    </div>
    <div class="hidden lg:block relative w-0 flex-1">
        <img class="absolute inset-0 h-full w-full object-cover" :src="'/shiny_truck.jpg'" alt="">
    </div>
</div>
</template>


